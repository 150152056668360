<template>
    <div class="prepaidPageSection">
      <div class="mobileNav">Support</div>
      <b-container class="profile">
        <!-- style - 1  -->
        <b-row class="p-3">
          <b-col cols="12" md="12" class="my-3 px-4">
            <h3 class="mb-4">Support Request</h3>
            <SupportForm />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>

<script>
import SupportForm from "./SupportComponents/SupportForm";

export default {
  name: "Support",
  // data() {
  //   return {
  //   };
  // },
  components: {
    SupportForm,
  },
};
</script>

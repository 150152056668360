<template>
  <div class="bg-white p-5 mycard">

    <b-form-input readonly id="emailTextInput" class=" my-4" v-model="email" placeholder="Email" rows="1" no-resize
      disabled></b-form-input>
    <b-dropdown id="mainCategoryDropdown" :text="selectedMainCategory" block class="my-dropdown my-4" menu-class="w-100">
      <b-dropdown-item v-for="(category, index) in mainCategories" :key="index" @click="selectMainCategory(category)"
        href="#">
        {{ category }}
      </b-dropdown-item>
    </b-dropdown>

    <!-- If main category selected -->
    <div :class="{ 'my-dimmed': !showSubCategory }">
      <b-dropdown v-if="!skipSubCategory" id="subCategoryDropdown" :text="selectedSubCategory" block variant="secondary"
        class="my-dropdown my-4" menu-class="w-100">
        <b-dropdown-item v-for="(category, index) in subCategories[selectedMainCategory]" :key="index"
          @click="selectSubCategory(category)" href="#">
          {{ category }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    
    <!-- If Subcategory selected -->
    <div :class="{ 'my-dimmed': !showFormFields }">
      <!-- <b-form-input id="subjectTextInput" class="my-input my-4" v-model="subject" placeholder="Subject *" rows="1"
        no-resize></b-form-input> -->
        <multiselect
        :value='this.subject'
        :options="filteredHelpTopics"
        :close-on-select="true"
        :clear-on-select="false"
        :show-labels="false"
        :show-no-results="false"
        :show-no-options="false"
        :multiple="false"
        :internal-search="false"
        preserve-search="true"
        :placeholder='""'
        label="text"
        track-by="text"
        @select="onSelectArticle"
        @search-change="OnInput"
      >
      <span slot="caret"></span>
      <span slot="noResult"></span>
      </multiselect>
      <b-form-textarea id="descriptionTextInput" v-model="description" placeholder="Describe your issue here *" rows="4"
        max-rows="6" class="my-input my-4"></b-form-textarea>

      <!-- File attach component -->
      <!-- <b-form-file v-model="file1" state=null placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."></b-form-file> -->

      <div class="text-center">
        <button :disabled="!validateForm()" :class="validateForm() ? 'mybtn my-4' : 'mybtn-block my-4'"
          @click="submitForm">
          Submit
        </button>
      </div>
    </div>

  </div>
</template>
  
<script>
import Multiselect from 'vue-multiselect'
import Elvio from 'elevio/lib/client';

export default {
  data() {
    const defaultMainCategory = "Please choose your issue below *";
    const defaultSubCategory = "Please tell us more about your issue*";
    const defaultSubject = "Subject *";
    return {
      defaultMainCategory: defaultMainCategory,
      defaultSubCategory: defaultSubCategory,
      defaultSubject: defaultSubject,

      descriptionRequired: false,
      skipSubCategory: false,
      showSubCategory: false,
      showFormFields: false,
      email: "",
      selectedMainCategory: defaultMainCategory,
      selectedSubCategory: defaultSubCategory,
      subject: {
        articleId: 0,
        text: defaultSubject
      },
      description: "",
      file1: null,
      mainCategories: [
        // "Please choose your issue below *",
        "Activations",
        "Plan Change",
        "Billing",
        "Complaints",
        "Suspension",
        "Termination",
        "Troubleshooting",
        "Shipping",
        "Other",
      ],
      subCategories: {
        "Activations": [
          "Schedule Activations",
          "Service Starting Date Changes",
          "Cancel Activation",
          "Refund Activation",
          "Others"
        ],
        "Plan Change": [
          "Prepaid Extension",
          "Monthly Plan",
          "Internal Port In",
          "Others"
        ],
        "Billing": [
          "Outstanding Balance Payment",
          "Method of payment (MOP) Changes",
          "Other types of payments issue"
        ],
        "Complaints": [
          "Data overage",
          "Service Disruption",
          "Service Delay",
          "Shipping Delay",
          "Credit Issue",
          "CCTS",
          "Others"
        ],
        "Suspension": [
          "Unpaid Suspension",
          "Lost & Stolen Suspension",
          "Vacation Plan Suspension",
          "Unused Service Suspension",
          "Others"
        ],
        "Termination": [
          "Account Termination",
          "Termination Changes",
          "Cancel Termination Request",
          "Others"
        ],
        "Troubleshooting": [
          "Data (Internet) Issue",
          "Call or Text Issue",
          "Entire Network Issue (SOS - no service)",
          "Others"
        ],
        "Shipping": [
          "Address update/modification",
          "Shipping cancellation",
          "Others"
        ]
      },
      helpTopics: [
        {articleId: 3, text: "How does the online activation work?"},
        {articleId: 4, text: "How can a traveler who has just arrived in Canada purchase a SIM card?"},
        {articleId: 6, text: "Is it possible to schedule the activation date of the SIM card?"},
        {articleId: 5, text: "Is it possible to purchase a SIM card outside of Canada?"},
        {articleId: 7, text: "What plans are available on PhoneBox?"},
        {articleId: 8, text: "How does a Monthly account work?"},
        {articleId: 9, text: "How does a prepaid account work?"},
        {articleId: 10, text: "How do I start using my PhoneBox SIM card?"},
        {articleId: 18, text: "Does PhoneBox charge a fee to change my plan?"},
        {articleId: 27, text: "Is it possible to change the plan anytime?"},
        {articleId: 28, text: "Can I upgrade or downgrade my plan?"},
        {articleId: 29, text: "How can I change my plan from Prepaid to Postpaid?"},
        {articleId: 30, text: "How can I change my plan from Postpaid to Prepaid?"},
        {articleId: 31, text: "How do I terminate my account?"},
        {articleId: 32, text: "Can I schedule the termination date of my account?"},
        {articleId: 33, text: "Do I get a refund for unused services when I terminate my account?"},
        {articleId: 34, text: "When will I get my refund after terminating my account?"},
        {articleId: 35, text: "How do I transfer my phone number to a different service provider?"},
        {articleId: 36, text: "When will PhoneBox deliver a SIM card?"},
        {articleId: 37, text: "Which plans are available at PhoneBox?"},
        {articleId: 38, text: "How good is the PhoneBox network coverage in Canada?"},
        {articleId: 39, text: "Why should I choose PhoneBox over other service providers?"},
        {articleId: 40, text: "Do postpaid plans have a lock-in contract?"},
        {articleId: 41, text: "Why have I not received my SIM card yet?"},
        {articleId: 42, text: "How can I track the delivery of my SIM card?"},
        {articleId: 43, text: "The online activation didn't work for my US plan. What should I do?"},
        {articleId: 44, text: "Why is the delivery of my SIM card delayed?"},
        {articleId: 45, text: "Why is no one responding to my emails?"},
        {articleId: 78, text: "What are Multiline Accounts?"},
        {articleId: 79, text: "What are the benefits of adding multiple accounts into one using the multi-lining account functionality?"},
        {articleId: 80, text: "How do I add a line on my online account using the multi-lining option?"},
        {articleId: 81, text: "How do I remove a line on my online account using the multi-lining option?"},
        {articleId: 70, text: "Does PhoneBox offer shipping of SIM cards?"},
        {articleId: 16, text: "What do I do when my phone is showing “No Service”?"},
        {articleId: 46, text: "Why can't I make outgoing calls?"},
        {articleId: 47, text: "Why can't I receive inbound calls?"},
        {articleId: 48, text: "Why can't I send text messages?"},
        {articleId: 49, text: "Why can't I receive text messages?"},
        {articleId: 50, text: "Is it possible to set-up an auto-extension for my prepaid plan?"},
        {articleId: 51, text: "What are the payment methods for prepaid plans?"},
        {articleId: 52, text: "What are the payment methods for postpaid plans?"},
        {articleId: 53, text: "What is the billing cycle for prepaid plans?"},
        {articleId: 54, text: "What is the billing cycle for postpaid plans?"},
        {articleId: 12, text: "How does a vacation plan work?"},
        {articleId: 13, text: "What is the difference between prepaid and postpaid accounts?"},
        {articleId: 14, text: "Can I extend my vacation plan?"},
        {articleId: 15, text: "I forgot to extend my prepaid plan after the 30-day service. How can I make a payment?"},
        {articleId: 55, text: "Do I get a notification when my prepaid plan is about to expire?"},
        {articleId: 56, text: "Do I get a notification when I have exceeded my data allowance?"},
        {articleId: 57, text: "Can I still receive calls and SMS when my prepaid plan has expired?"},
        {articleId: 58, text: "How do I add more data to my prepaid plan?"},
        {articleId: 59, text: "Does a prepaid line expire?"},
        {articleId: 60, text: "What is eSIM?"},
        {articleId: 62, text: "How does eSIM activation work?"},
        {articleId: 61, text: "What plans are available with eSIM at PhoneBox?"},
        {articleId: 63, text: "Are all devices compatible with eSIM?"},
        {articleId: 76, text: "Can I port in my phone number from my previous mobile carrier when I already have my PhoneBox's account activated?"},
        {articleId: 77, text: "Can I port-in a landline phone number to PhoneBox?"},
        {articleId: 64, text: "How will I know my Phone number?"},
        {articleId: 65, text: "Can I change the start date of the service with PhoneBox?"},
        {articleId: 66, text: "Is my device compatible with PhoneBox network?"},
        {articleId: 67, text: "What is data overage fee and how does it work?"},
        {articleId: 68, text: "Can I sign-up for a PhoneBox plan if I am under 18 years old?"},
        {articleId: 69, text: "Can I choose my phone number when I sign up?"},
        {articleId: 71, text: "Can I use an international credit card to pay for a US phone number?"},
        {articleId: 72, text: "Can I pay for the US phone number in cash?"},
        {articleId: 73, text: "Do I have to contact Phonebox to terminate a US plan?"},
        {articleId: 74, text: "How good is the Phonebox network coverage in the US?"},
        {articleId: 75, text: "What is PhoneBox network speed in the US?"},
        {articleId: 82, text: "Do PhoneBox USA plans include international calling services?"}
      ],
      filteredHelpTopics: []
    };
  },
  components: {
    Multiselect
  },
  methods: {
    onSelectArticle(option) {
      Elvio.openArticle(option.articleId);
    },
    OnInput (newInput) {
      if (newInput === ""){
        this.filteredHelpTopics = [];
      }
      else {
        this.filteredHelpTopics = this.helpTopics.filter((item) => {
          return item.text.toLowerCase().includes(newInput.toLowerCase());
        });
      }
      
      const newSubject = {
        articleId: 0,
        text: newInput
      };
      this.setSubject(newSubject);
    },
    setSubject(myObject) {
      if (myObject.text === ""){
        myObject.text = this.defaultSubject;
      }
      this.subject = myObject;
    },
    getProfile() {
      this.$axios
        .get("/Account/GetProfile")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.email = data.Email;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitForm() {
      const formData = {
        "Notes": this.subject.text + " - " + this.description,
        "MainCategory": this.selectedMainCategory,
        "SubCategory": this.selectedSubCategory,
      }
      this.$axios
        .post("/Tickets/ActivatedCustomer/New", formData)
        .then((response) => {
          if (response.status === 204) {
            this.onSuccessfulSubmit();
          }
          else {
            this.onFailedSubmit();
          }
        })
        .catch((error) => {
          console.log(error);
          this.onFailedSubmit();
        });
    },
    onSuccessfulSubmit() {
      this.resetForm();
      this.$notify({
        title: "Submitted",
        message: "Thank you. Your request was successfully submitted",
        type: "success",
        duration: 0,
        onClose: () => {
          this.$router.go();
        },
      });
    },
    onFailedSubmit() {
      this.$notify({
        title: "Error",
        message: "Please try submit your form again!",
        type: "error",
        duration: 0,
      });
    },
    selectMainCategory(category) {
      this.selectedMainCategory = category;
      this.selectedSubCategory = this.defaultSubCategory;
      this.checkShowSubcategory();
      this.setShowSubcategory();
      this.setShowFormFields();
    },
    selectSubCategory(category) {
      this.selectedSubCategory = category;
      this.checkShowSubcategory();
      this.setShowSubcategory();
      this.setShowFormFields();
    },
    checkShowSubcategory() {
      if (this.selectedMainCategory === 'Other') {
        this.skipSubCategory = true;
        this.descriptionRequired = true;
        return false;
      }
      else {
        this.skipSubCategory = false;
        this.descriptionRequired = false;
        return true;
      }
    },
    setShowSubcategory() {
      if (this.selectedMainCategory === 'Other' || this.selectedMainCategory === this.defaultMainCategory) {
        this.showSubCategory = false;
        return false;
      }
      else {
        this.showSubCategory = true;
        return true;
      }
    },
    setShowFormFields() {
      if ((this.selectedSubCategory === this.defaultSubCategory) && !this.skipSubCategory) {
        this.showFormFields = false;
        return false;
      }
      else {
        this.showFormFields = true;
        return true;
      }
    },
    validateForm() {
      // Add form validation logic here
      if (
        this.selectedMainCategory === this.defaultMainCategory ||
        (this.selectedSubCategory === this.defaultSubCategory && !this.skipSubCategory) ||
        this.subject.text === this.defaultSubject ||
        (this.description === "" && this.descriptionRequired)
      ) {
        return false;
      }
      return true;
    },
    resetForm() {
      this.selectedMainCategory = this.defaultMainCategory;
      this.selectedSubCategory = this.defaultSubCategory;
      this.subject = {
        articleId: 0,
        text: this.defaultSubject
      };
      this.description = "";
      this.file1 = null;
    },
  },
  mounted() {
    this.getProfile();
  },
};
</script>
  
<style lang="scss" scoped></style>
